import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { whitelabel } from '../../../whitelabels/whitelabel';

@Injectable()

export class StaticService {
  private unixTimeStamp: number = Math.round((new Date()).getTime() / 1000);

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  public getSlideshow(): Observable<any> {


    let _slideshowUrl = environment.slideshowUrl.leolux;
    if (whitelabel.pode) {
      _slideshowUrl = environment.slideshowUrl.pode;
    } else if (whitelabel.lx) {
      _slideshowUrl = environment.slideshowUrl.lx;
    } else if (whitelabel.press) {
      _slideshowUrl = environment.slideshowUrl.press;
    } else if (whitelabel.evidence) {
      _slideshowUrl = environment.slideshowUrl.evidence;
    }

    return this.http.get<any>(_slideshowUrl, {
      params: {
        'v': this.unixTimeStamp.toString()
      }
    });
  }

  public getHomepageLinks(): Observable<any> {
    let _lang = 'NL';
    if (this.translate.currentLang === 'en-US') {
      _lang = 'EN';
    } else {
      _lang = (this.translate.currentLang.indexOf('-') !== -1) ? this.translate.currentLang.substr(this.translate.currentLang.indexOf('-') + 1) : 'NL';
    }

    let _imanagerUrl = environment.imanagerUrl.leolux;
    if (whitelabel.pode) {
      _imanagerUrl = environment.imanagerUrl.pode;
    } else if (whitelabel.lx) {
      _imanagerUrl = environment.imanagerUrl.lx;
    } else if (whitelabel.press) {
      _imanagerUrl = environment.imanagerUrl.press;
    } else if (whitelabel.professional) {
      _imanagerUrl = environment.imanagerUrl.professional;
    } else if (whitelabel.evidence) {
      _imanagerUrl = environment.imanagerUrl.evidence;
    }

    return this.http.get<any>(_imanagerUrl + '/mx' + _lang, {
      params: {
        'v': this.unixTimeStamp.toString()
      }
    });
  }
}
