export const whitelabel = {
  identifier: 'Q4D2',
  press: false,
  professional: false,
  consumer: true,
  leolux: false,
  pode: false,
  lx: false,
  evidence: true,
};
